import React from 'react';
import {Helmet} from 'react-helmet-async';
import Image from 'common/image';
import Link from 'router/Link';
import Concepts from './components/concepts';
import Integrations from './components/integrations';
import News from './components/news';
import PartnerIcons from './components/partner-icons';
import PlatformValues from './components/platform-values';
import {accessEmail} from './utils';

const results = ['1', '2', '3', '4', '5'];

const Developers = () => {
    return (
        <div className="Developers">
            <Helmet>
                <title>SpotHero Developer Platform, Parking API</title>
                <meta
                    name="description"
                    itemProp="description"
                    content="Use the SpotHero API to empower your website, mobile app, connected car or self-driving car with parking functionality."
                />
            </Helmet>
            <div className="Developers-map">
                <section className="Developers-section Developers-section-overview">
                    <div className="Developers-overview">
                        <header>
                            <h1 className="Typography-developers-h1">
                                The #1 Parking API & Developer Platform Built on
                                the Largest Consumer Marketplace
                            </h1>
                        </header>
                        <PlatformValues className="Developers-values" />
                        <footer className="Developers-overview-buttons">
                            <Link
                                className="Button Button-primary Developers-overview-button"
                                forceAnchor
                                to={accessEmail('Request Access')}
                            >
                                Request Access
                            </Link>
                            <Link
                                className="Button Button-primary Developers-overview-button"
                                to="https://api.spothero.com/v2/docs"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                View Developer Docs
                            </Link>
                        </footer>
                    </div>
                    <div className="Developers-results">
                        {results.map(item => (
                            <div className="Developers-result" key={item} />
                        ))}
                    </div>
                </section>
                <section className="Developers-section Developers-section-concepts">
                    <header>
                        <h2 className="Typography-developers-h2">
                            Engineering Better Ways to Get From A to B
                        </h2>
                        <p className="Typography-developers-description">
                            Our flexible parking API and developer toolkit
                            connects all types of vehicles and drivers with the
                            largest network of off-street parking facilities in
                            North America.
                        </p>
                    </header>
                    <Concepts className="Developers-concepts" />
                    <PartnerIcons className="Developers-partners" />
                </section>
            </div>
            <section className="Developers-section Developers-section-integrations">
                <header>
                    <h2 className="Typography-developers-h2">
                        Developers Put Parking in Motion
                    </h2>
                    <p className="Typography-developers-description-large">
                        Customizable solutions, lightweight code, and robust
                        documentation for every scenario. It&apos;s the #1
                        platform for parking everything — plus millions of spots
                        to do it.
                    </p>
                </header>
                <Integrations className="Developers-integrations" />
            </section>
            <section className="Developers-section Developers-section-docs">
                <div className="Developers-docs">
                    <div className="Deverlopers-docs-container">
                        <header>
                            <h2 className="Typography-developers-h2">
                                Detailed Developer Docs
                            </h2>
                            <p className="Typography-developers-description-large">
                                Delivering sample requests and responses and
                                in-depth developer support.
                            </p>
                        </header>
                        <Link
                            className="Button Button-secondary Developers-docs-button"
                            to="https://api.spothero.com/v2/docs"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            View Developer Docs
                        </Link>
                    </div>
                    <Image
                        className="Developers-docs-snippet"
                        cloudinaryImageId="consumer-web-spa/developers/code-snippet"
                        height={361}
                        quality={95}
                        width={521}
                    />
                </div>
            </section>
            <section className="Developers-section Developers-section-news">
                <header>
                    <h2 className="Typography-developers-h2">
                        SpotHero in the News
                    </h2>
                </header>
                <News className="Developers-news" />
            </section>
            <section className="Developers-section Developers-section-pricing">
                <header>
                    <h1 className="Typography-developers-h1">
                        Ready to get started? Got questions about pricing?
                    </h1>
                </header>
                <Link
                    className="Button Button-secondary Developers-pricing-button"
                    forceAnchor
                    to={accessEmail('Request Info')}
                >
                    Request Info
                </Link>
                <p className="Typography-developers-description-large Developers-pricing-copy">
                    Drop us a line. We&apos;ll reach out soon to learn more
                    about your goals.
                </p>
            </section>
        </div>
    );
};

export default Developers;
