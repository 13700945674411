export const accessEmail = title =>
    `mailto:partner.support@spothero.com?subject=SpotHero API | ${title}&body=${[
        'Name',
        'Company',
        'Title',
        'Corporate Email',
        'Anticipated Number of Reservations per Month',
    ]
        .map(item => `${encodeURIComponent(item)}:`)
        .join('%0D%0A')}`;
