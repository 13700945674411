import React from 'react';
import PropTypes from 'prop-types';
import NewsFeature from './NewsFeature';

const features = [
    {
        title: 'TechCrunchApple',
        description:
            'Apple Maps teams up with SpotHero to give users access to 8K parking options.',
        imageID: 'apple-newsfeed',
        dimensions: {
            height: 100,
            width: 180,
        },
        url:
            'https://techcrunch.com/2023/01/09/apple-maps-spothero-parking-feature',
    },
    {
        title: 'TechCrunchGoogle',
        description:
            'Google’s partnership with SpotHero brings parking to Maps and Search.',
        imageID: 'google-newsfeed',
        dimensions: {
            height: 100,
            width: 180,
        },
        url:
            'https://techcrunch.com/2024/10/08/google-partners-with-spothero-convenient-parking-reservations-maps-search/',
    },
];

const articles = [
    {
        title: 'NBCChicago',
        description:
            'SpotHero, the go-to app for bargain parking spots has announced a new partnership with Apple Maps.',
        imageID: 'chicago-news',
        dimensions: {
            height: 60,
            width: 350,
        },
        url:
            'https://www.nbcchicago.com/top-videos-home/tech-tends-spot-hero-and-apple-maps-integration/3045854/',
    },
    {
        title: 'YahooNews',
        description:
            'Take the stress out of driving and how SpotHero may come in handy this Super Bowl Sunday.',
        imageID: 'yahoo-news',
        dimensions: {
            height: 80,
            width: 300,
        },
        url:
            'https://news.yahoo.com/super-bowl-parking-going-mayhem-201948002.html',
    },
];

const NewsFeatures = () => {
    return (
        <div className="NewsBlocks">
            <div className="NewsFeatures NewsFeatures-static">
                {features.map((feature, index) => (
                    <div className="NewsFeatures-item" key={index}>
                        <NewsFeature {...feature} />
                    </div>
                ))}
            </div>

            <br />
            <br />
            <br />

            <div className="NewsFeatures NewsFeatures-static">
                {articles.map((article, index) => (
                    <div className="NewsFeatures-item" key={index}>
                        <NewsFeature {...article} />
                    </div>
                ))}
            </div>

            <br />
            <br />
        </div>
    );
};

NewsFeatures.propTypes = {
    className: PropTypes.string,
};

export default NewsFeatures;
