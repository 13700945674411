import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from 'common/image';
import Link from 'router/Link';

const NewsFeature = ({title, description, imageID, dimensions, url}) => (
    <div>
        {!imageID && <p>{title}</p>}
        {imageID && (
            <Image
                alt={title}
                cloudinaryImageId={`consumer-web-spa/developers/press/${imageID}`}
                quality={95}
                {...dimensions}
            />
        )}
        <p
            className={classNames(
                'Typography-developers-description',
                'NewsFeature-copy'
            )}
        >
            {`${description} `}
            <Link
                className="NewsFeature-link"
                forceAnchor
                to={url}
                target="_blank"
                rel="noopener noreferrer"
            >
                Read more
            </Link>
        </p>
    </div>
);

NewsFeature.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    imageID: PropTypes.string,
    dimensions: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number,
    }),
    url: PropTypes.string,
};

export default NewsFeature;
