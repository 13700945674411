import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconMap from '@spothero/icons/map';
import IconParking from '@spothero/icons/parking-filled';
import IconSort from '@spothero/icons/sort';

const values = [
    {
        icon: IconSort,
        value: 'Lightweight and scalable platform',
    },
    {
        icon: IconParking,
        value: '75M+ cars parked',
    },
    {
        icon: IconParking,
        value: '$1Billion+ sold',
    },
    {
        icon: IconMap,
        value: 'Parking facilities in 300 cities',
    },
];

const PlatformValues = ({className}) => {
    return (
        <ul className={classNames('PlatformValues', className)}>
            {values.map(({value}, i) => {
                const Icon = values[i].icon;

                return (
                    <li key={value}>
                        <p className="Typography-developers-description-large PlatformValues-value">
                            <Icon className="PlatformValues-icon" />
                            {value}
                        </p>
                    </li>
                );
            })}
        </ul>
    );
};

PlatformValues.propTypes = {
    className: PropTypes.string,
};

export default PlatformValues;
