import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from 'common/image';
import withConfig from '@/config/withConfig';

const partners = [
    'MSG',
    'Fenway',
    'Wrigley',
    'OraclePark',
    'UnitedCenter',
    'Amtrak',
    'RadioCity',
    'Concur',
    'Apple',
    'Google',
    'Parkopedia',
    'Android',
];

const PartnerIcons = ({className}) => {
    return (
        <div className={classNames('PartnerIcons', className)}>
            {partners
                .reduce(
                    (rows, item, i) => {
                        if (i === 1) {
                            // Distribute rows with more in the first one
                            rows[0].push(item);
                        } else {
                            rows[i % 2 === 0 ? 0 : 1].push(item);
                        }

                        return rows;
                    },
                    [[], []]
                )
                .map((row, i) => (
                    <ul className="PartnerIcons-list" key={i}>
                        {row.filter(Boolean).map((partner, j) => (
                            <li className="PartnerIcons-icon" key={j}>
                                <Image
                                    alt={`Logo of ${partner}`}
                                    cloudinaryImageId={`consumer-web-spa/developers/partners/partner-${partner.toLowerCase()}`}
                                    height={105}
                                    quality={95}
                                    width={105}
                                />
                            </li>
                        ))}
                    </ul>
                ))}
        </div>
    );
};

PartnerIcons.propTypes = {
    className: PropTypes.string,
};

export default withConfig(['staticBaseUrl'])(PartnerIcons);
