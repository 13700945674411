import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const concepts = [
    {
        title: 'Urban Mobility',
        body:
            'Parking is a critical piece of mobility. Offer it up to your audience and solve for the last mile.',
    },
    {
        title: 'Navigation',
        body:
            'Reduce friction and congestion by getting users from their front door into a parking facility, fast.',
    },
    {
        title: 'Carshare & Rentals',
        body:
            'Make sure your fleet drivers and customers always have a place to park — and solve for access & payments, too.',
    },
    {
        title: 'Connected Car',
        body:
            'Minimize driver distractions by putting parking where it belongs: in the dashboard.',
    },
    {
        title: 'Things to Do',
        body:
            'Reduce anxiety for event guests by empowering them to plan for parking in advance.',
    },
    {
        title: 'Services',
        body:
            'Parking is complicated. Let us do the legwork to secure your spots through our partnership network.',
    },
];

const Concepts = ({className}) => {
    return (
        <ul className={classNames('Concepts', className)}>
            {concepts.map(({title, body}) => (
                <li className="Concepts-item" key={title}>
                    <h2 className="Concepts-title">{title}</h2>
                    <p>{body}</p>
                </li>
            ))}
        </ul>
    );
};

Concepts.propTypes = {
    className: PropTypes.string,
};

export default Concepts;
