import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from 'common/image';
import Link from 'router/Link';
import {accessEmail} from '../../utils';

const integrations = [
    {
        title: 'Parking API',
        description:
            'Parking right in your product, exactly how you like it. Our parking API gives you full control over the look, flow, and feel.',
        valueProps: [
            'Customize the user interface',
            'Deliver a native experience',
            'Put your logo on the parking pass',
            'Full-service customer support for parking reservations',
        ],
        button: 'View Parking API Docs',
        url: 'https://api.spothero.com/v2/docs',
        img: 'screenshot-api',
        dimensions: {
            height: 537,
            width: 330,
        },
    },
    {
        title: 'Parking Web Widgets',
        description:
            '700+ partners use our plug-and-play widgets to add parking to their websites for events and destinations.',
        valueProps: [
            'Copy-and-paste code snippet',
            'Largest network of facilities',
            'SpotHero checkout experience',
            'Boosted event parking revenue',
        ],
        button: 'View Widget Docs',
        url: '',
        img: 'screenshot-widget',
        dimensions: {
            height: 330,
            width: 360,
        },
    },
    {
        title: 'Parking Link Routing',
        description:
            'Easily configure the SpotHero app or webpages to open through yours. Minimal technical development required.',
        valueProps: [
            'Easy setup with copy and pasting links',
            'Link to relevant parking on our website or app',
            'Provide easy purchase experience',
            'Configure by destination or garage by garage basis',
        ],
        button: 'View Link Routing Docs',
        url: '',
        img: 'screenshot-deeplinks',
        dimensions: {
            height: 322,
            width: 352,
        },
    },
];

const Integrations = ({className}) => {
    return (
        <div className={classNames('Integrations', className)}>
            {integrations.map(
                (
                    {
                        title,
                        description,
                        valueProps,
                        button,
                        url,
                        img,
                        dimensions,
                    },
                    i
                ) => (
                    <div className="Integrations-integration" key={i}>
                        <div className="Integrations-content">
                            <div className="Integrations-separator" />
                            <h2 className="Integrations-title">{title}</h2>
                            <p
                                className="Typography-developers-description-large"
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            />
                            <ul className="Integrations-list">
                                {valueProps.map((item, j) => (
                                    <li key={j}>
                                        <p className="Typography-developers-description-large">
                                            {item}
                                        </p>
                                    </li>
                                ))}
                            </ul>
                            <Link
                                className="Button Button-primary Integrations-button"
                                forceAnchor
                                to={url ? url : accessEmail(button)}
                                {...(url && {
                                    target: '_blank',
                                    rel: 'noopener noreferrer',
                                })}
                            >
                                {url ? button : 'Contact for Info'}
                            </Link>
                        </div>
                        <div className="Integrations-image">
                            <Image
                                cloudinaryImageId={`consumer-web-spa/developers/integrations/${img}`}
                                alt={`Screenshot of ${title}`}
                                quality={95}
                                {...dimensions}
                            />
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

Integrations.propTypes = {
    className: PropTypes.string,
};

export default Integrations;
