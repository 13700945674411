import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NewsFeatures from '../news-features';

const News = ({className}) => {
    return (
        <div className={classNames('News', className)}>
            <NewsFeatures />
        </div>
    );
};

News.propTypes = {
    className: PropTypes.string,
};

export default News;
